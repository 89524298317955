<template>
  <v-card class="col-sm-6 mx-auto" width="800" elevation="12">
    <v-card-text>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="10">
          <v-img alt="D.I. logo" src="../assets/unimi-DI-logo.png"></v-img>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-card-text>
    <v-card-title class="text-center">
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="10"> Reset password </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="10">
          <v-row v-if="validCode">
            Inserire la nuova password.
            <v-form @submit="onSubmit" @reset="onReset" validated>
              <v-text-field
                id="password1"
                v-model="password1"
                type="password"
                label="Password:"
                required
                placeholder="Enter password"
              ></v-text-field>
              <v-text-field
                id="password2"
                v-model="password2"
                type="password"
                label="Password (conferma):"
                required
                placeholder="Enter password"
              ></v-text-field>
              <span :show="!passwordValid" fade class="red--text">
                {{ passwordMessage }} </span
              ><br />
              <v-btn type="submit" color="primary" :disabled="!passwordValid">Cambia password</v-btn>
            </v-form>
          </v-row>
          <v-row v-else>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="10"> Il codice di reset non &egrave; valido. </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </v-row>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-card-text>
    <v-snackbar v-model="showSnackbar" :timeout="timeout" :color="color">
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="showSnackbar = false">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
const axios = require("axios");
const qs = require("querystring");
export default {
  components: {},
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
  },
  data() {
    return {
      showSnackbar: false,
      color: "error",
      message: "",
      timeout: 3000,
      validCode: false,
      password1: "",
      password2: "",
      equalPasswords: true,
      passwordChanged: false,
      passwordMessage: '',
      passwordValid: false,
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.resetPassword();
    },
    async resetPassword() {
      this.$store.state.isLoading = true;
      var parameters = {
        password: this.password1,
        code: this.$route.params.code,
      };
      try {
        let response = await axios.post(
          process.env.VUE_APP_IPA_API + "/verifyresetcode",
          qs.stringify(parameters),
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        this.risposta = response.data;
        if (response.data.httpStatus === 200) {
          this.color = "success";
          this.message = "La password è stata corretamente impostata.";
          this.timeout = 3000;
          this.password1 = "";
          this.password2 = "";
          this.passwordChanged = true;
          this.showSnackbar = true;
        } else {
          this.color = "error";
          this.timeout = 4000;
          this.message = response.data.longMessage;
          this.showSnackbar = true;
        }
        this.$store.state.isLoading = false;
      } catch (response) {
        this.colot = "error";
        this.timeout = 4000;
        this.message = response;
        this.$store.state.isLoading = false;
        this.showSnackbar = true;
      }
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.password1 = "";
      this.password2 = "";
    },
    checkPasswords() {
      if (
        !this.password1 ||
        this.password1 === "" ||
        !this.password2 ||
        this.password2 === ""
      ) {
        this.passwordMessage =
          "Le password sono obbligatorie";
        this.passwordValid = false;
        return;
      } else if (this.password1 !== this.password2) {
        this.passwordMessage = "Le password non coincidono";
        this.passwordValid = false;
        return;
      }
      this.passwordValid = true;
      this.passwordMessage = "";
    },
  },
  created: async function () {
    this.$store.state.isLoading = true;
    var parameters = { code: this.$route.params.code };
    try {
      let response = await axios.post(
        process.env.VUE_APP_IPA_API + "/verifyresetcode",
        qs.stringify(parameters),
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      this.$store.state.isLoading = false;
      if (response.data.httpStatus === 200) {
        this.validCode = true;
      } else {
        this.validCode = false;
      }
    } catch (response) {
      this.$store.state.isLoading = false;
      this.validCode = false;
    }
  },
  watch: {
    password1() {
      this.checkPasswords();
    },
    password2() {
      this.checkPasswords();
    },
  },
};
</script>

<style>
label {
  text-align: right;
  vertical-align: middle;
  display: inline-block;
}
.col-sm-2 {
  margin-right: 0px;
  vertical-align: middle;
}
.col-sm-10 {
  margin-left: 0px;
}
.col-sm-10 label {
  margin-left: 0px;
}
.errorMessage {
  text-align: left;
  margin-top: 10px;
}
</style>
