import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import UserLogin from '../components/UserLogin.vue'
import ConfirmEmail from '../components/ConfirmEmail.vue'
import ForgottenPassword from '../components/ForgottenPassword.vue'
import ResetPassword from '../components/ResetPassword.vue'
const jwt = require('jsonwebtoken')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeView',
    meta: {
      title: 'D.I. accounts'
    },
    component: HomeView
  },
  {
    path: '/login',
    name: 'UserLogin',
    meta: {
      title: 'D.I. accounts - Login'
    },
    component: UserLogin
  },
  {
    path: '/recover',
    name: 'ForgottenPassword',
    meta: {
      title: 'D.I. accounts - Forgotten password'
    },
    component: ForgottenPassword
  },
  {
    path: '/resetpassword/:code',
    name: 'ResetPassword',
    meta: {
      title: 'D.I. accounts - Password reset'
    },
    component: ResetPassword
  },
  {
    path: '/confirmemail/:code',
    name: 'ConfirmEmail',
    meta: {
      title: 'D.I. accounts - Email confirmation'
    },
    component: ConfirmEmail
  }
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/recover', '/resetpassword/']
  var authRequired = !publicPages.includes(to.path)
  document.title = to.meta.title
  for (var publicPage of publicPages) {
    if (to.path.search(publicPage) !== -1) {
      authRequired = false
    }
  }
  var loggedIn = localStorage.getItem('auth_token')
  
  if (loggedIn) {
    loggedIn = loggedIn.toString()
    try {
      var payload = jwt.decode(loggedIn)
      if (payload.exp < Math.floor(Date.now() / 1000)) {
        // JWT expired
        localStorage.clear()
        return next('/login')
      }
    } catch (error) {
      // Not a valid JWT
      localStorage.clear()
      return next('/login')
    }
  } else {
    // No JWT present in local storage
    if (authRequired) {
      return next('/login')
    }
  }
  next()
})



export default router
