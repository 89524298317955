<template>
  <v-container>
    <top-header></top-header>
    <user-profile></user-profile>
  </v-container>
</template>

<script>
import TopHeader from '../components/TopHeader.vue'
import UserProfile from '../components/UserProfile.vue'
  export default {
    name: 'HomeView',

    components: {
      TopHeader,
      UserProfile
    },
  }
</script>
