<template>
  <v-card class="col-sm-6 mx-auto" width="800" elevation="12">
    <v-card-text>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="10">
          <v-img
            alt="D.I. logo"
            width="70%"
            src="../assets/unimi-DI-logo.png"
          ></v-img>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-card-text>
    <v-card-title>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="3" class="text-center"> Conferma email </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-card-title>
    <v-card-text v-if="validCode">
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="3" class="text-center"
          >L'email &egrave; stata correttamente aggiunta.</v-col
        >
        <v-spacer></v-spacer>
      </v-row>
    </v-card-text>
    <v-card-text v-else>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="3" class="text-center"
          >Il codice di aggiunta email non &egrave; valido.</v-col
        >
        <v-spacer></v-spacer>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
const axios = require("axios");
const qs = require("querystring");
export default {
  components: {},
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
  },
  data() {
    return {
      validCode: false,
    };
  },
  methods: {},
  created: async function () {
    this.$store.state.isLoading = true;
    var parameters = { code: this.$route.params.code };
    try {
      let response = await axios.post(
        process.env.VUE_APP_IPA_API + "/confirmemail",
        qs.stringify(parameters),
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      this.$store.state.isLoading = false;
      if (response.data.httpStatus === 200) {
        this.validCode = true;
      } else {
        this.validCode = false;
      }
    } catch (response) {
      this.$store.state.isLoading = false;
      this.validCode = false;
    }
  },
};
</script>

<style>
label {
  text-align: right;
  vertical-align: middle;
  display: inline-block;
}
.col-sm-2 {
  margin-right: 0px;
  vertical-align: middle;
}
.col-sm-10 {
  margin-left: 0px;
}
.col-sm-10 label {
  margin-left: 0px;
}
.errorMessage {
  text-align: left;
  margin-top: 10px;
}
</style>
