<template>
  <v-card class="col-sm-6 mx-auto" width="800" elevation="12">
    <v-card-text>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="10">
          <v-img alt="D.I. logo" src="../assets/unimi-DI-logo.png"></v-img>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-card-text>
    <v-card-title>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="10" class="text-center"> Recupero password </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="10">
          Verr&agrave; inviata una email ad ognuno degli indirizzi
          precedentemente indicati per il recupero degli account. Se non si ha
          accesso a nessuna di queste caselle di posta si prega di rivolgersi
          allo staff IT.
          <v-form @submit="onSubmit" @reset="onReset">
            <v-text-field
              id="username"
              v-model="username"
              type="text"
              label="Username"
              required
              placeholder="Enter username"
            ></v-text-field>
            <p v-if="!validUsername" class="red--text">{{ usernameMessage }}</p>
            <p class="text-center">
              <v-btn type="submit" color="primary" :disabled="!validUsername"
                >Recover</v-btn
              >
            </p>
            <v-snackbar v-model="showSnackbar" :timeout="timeout" :color="color">
              <template v-slot:action="{ attrs }">
                <v-btn icon v-bind="attrs" @click="showSnackbar = false">
                  <v-icon small>mdi-close</v-icon>
                </v-btn>
              </template>
              {{ message }}
            </v-snackbar>
          </v-form>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
const axios = require("axios");
const qs = require("querystring");
export default {
  components: {},
  data() {
    return {
      message: "",
      timeout: 3000,
      showSnackbar: false,
      color: "success",
      username: "",
      recoverErrorDismissSecs: 5,
      recoverErrorDismissCountDown: 0,
      recoverErrorMessage: "",
      alertVariant: "danger",
      validUsername: false,
      usernameMessage: "",
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
  },
  watch: {
    username() {
      if (this.username.includes("@")) {
        this.usernameMessage =
          "Inserire lo username senza realm (e.g. non l'email)";
        this.validUsername = false;
      } else if (this.username === "") {
        this.usernameMessage = "Lo username è necessario";
        this.validUsername = false;
      } else {
        this.validUsername = true;
      }
    },
  },
  methods: {
    scrambleEmail(email) {
      var parts = email.split("@");
      var scrambled = parts[0].substring(0, 2);
      return scrambled + "***@" + parts[1];
    },
    recoverErrorCountDownChanged(dismissCountDown) {
      this.recoverErrorDismissCountDown = dismissCountDown;
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.recover();
    },
    async recover() {
      this.$store.state.isLoading = true;
      var parameters = {
        username: this.username,
      };
      try {
        let response = await axios.post(
          process.env.VUE_APP_IPA_API + "/recover",
          qs.stringify(parameters),
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        this.risposta = response.data;
        if (response.data.httpStatus === 200) {
          this.color = "success";
          this.message =
            "Sono state inviate email con le istruzioni di recupero ai tuoi indirizzi";
          //   let first = true;
          // for (var email of response.data.emails) {
          //   if(!first) {
          //     this.message = this.message + ", "
          //   }
          //   first = false;
          //   this.message =
          //     this.message + this.scrambleEmail(email);
          // }
          this.message =
            this.message +
            ". I codici di recupero sono validi per due ore.";
          this.showSnackbar = true;
          // this.username = "";
        } else {
          this.color = "error";
          this.message = response.data.longMessage;
          this.showSnackbar = true;
        }
        this.$store.state.isLoading = false;
      } catch (response) {
        this.color = "error";
        this.message = response;
        this.$store.state.isLoading = false;
        this.showSnackbar = true;
      }
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.username = "";
    },
  },
};
</script>

<style>
label {
  text-align: right;
  vertical-align: middle;
  display: inline-block;
}
.col-sm-2 {
  margin-right: 0px;
  vertical-align: middle;
}
.col-sm-10 {
  margin-left: 0px;
}
.col-sm-10 label {
  margin-left: 0px;
}
.errorMessage {
  text-align: left;
  margin-top: 10px;
}
</style>
