<template>
  <v-card class="col-sm-6 mx-auto" width="800" elevation="12">
    <v-card-text>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="10">
          <v-img alt="D.I. logo" src="../assets/unimi-DI-logo.png"></v-img>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="3" class="text-center"> Account management </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-form @submit="onSubmit" @reset="onReset">
        <v-text-field
          id="username"
          label="Username:"
          v-model="form.username"
          required
          placeholder="Enter username"
        ></v-text-field>
        <v-text-field
          id="password"
          v-model="form.password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
          label="Password:"
          required
          placeholder="Enter password"
        ></v-text-field>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="3" class="text-center">
            <v-btn color="primary" :disabled="buttonDisabled" type="submit">
              <span v-if="!loading">Login</span>
              <span v-else
                ><v-progress-circular indeterminate></v-progress-circular>
                Loading...</span
              >
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-snackbar v-model="showSnackbar" :timeout="timeout" color="error">
          {{ message }}

          <template v-slot:action="{ attrs }">
            <v-btn icon v-bind="attrs" @click="showSnackbar = false">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-form>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="3" class="text-center">
          <router-link to="/recover">Forgot password?</router-link>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
const axios = require("axios");
const qs = require("querystring");

axios.defaults.timeout = 3000;

export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      showPassword: false,
      risposta: "",
      dismissSecs: 3,
      showSnackbar: false,
      timeout: 3000,
      message: "",
      loading: false,
      formMessage: "",
    };
  },
  computed: {
    buttonDisabled() {
      if (this.loading) {
        this.formMessage = "";
        return true;
      }
      if (
        !this.form.username ||
        !this.form.password ||
        this.form.username === "" ||
        this.form.password === ""
      ) {
        this.formMessage = "Username and password needed";
        return true;
      }
      if (this.form.username.includes("@")) {
        this.formMessage =
          "Insert only username, without realm (e.g. no email)";
        return true;
      }
      return false;
    },
  },
  methods: {
    async login() {
      this.loading = true;
      var parameters = {
        username: this.form.username,
        password: this.form.password,
      };
      try {
        this.risposta = await axios.post(
          process.env.VUE_APP_IPA_API + "/login",
          qs.stringify(parameters),
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        if (this.risposta.data.httpStatus === 200) {
          localStorage.setItem("auth_token", this.risposta.data.jwt);
          this.$router.push({ path: "/" });
        } else {
          this.message = this.risposta.data.longMessage;
          this.showSnackbar = true;
        }
        this.loading = false;
      } catch (err) {
        this.risposta = err;
        this.message = err;
        this.showSnackbar = true;
        this.loading = false;
      }
    },
    async onSubmit(evt) {
      evt.preventDefault();
      this.login();
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.username = "";
      this.form.password = "";
    },
  },
  created: function() {
  }
};
</script>

<style>
label {
  text-align: right;
  vertical-align: middle;
  display: inline-block;
}
.col-sm-2 {
  margin-right: 0px;
  vertical-align: middle;
}
.col-sm-10 {
  margin-left: 0px;
}
.col-sm-10 label {
  margin-left: 0px;
}
a {
  text-decoration: none;
}
</style>
