<template>
  <div>
    <v-snackbar v-model="showSnackbar" :timeout="timeout" :color="color">
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="showSnackbar = false">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="removeEmailDialog" width="600">
      <v-card elevation="12">
        <v-card-title>Conferma</v-card-title>
        <v-card-text
          >Confermi la rimozione dell'email {{ emailToRemove }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="removeEmail()">SI</v-btn>
          <v-btn color="primary" @click="removeEmailDialog = false">NO</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addEmailDialog" width="600">
      <v-card elevation="12">
        <v-card-title>Aggiungi email</v-card-title>
        <v-card-text>
          <v-text-field v-model="email" label="email"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="addEmail()" :disabled="!emailValid"
            >OK</v-btn
          >
          <v-btn color="primary" @click="addEmailDialog = false">Annulla</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="changePasswordDialog" width="600">
      <v-card elevation="12">
        <v-card-title>Cambio password</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="oldpassword"
            label="password attuale"
            :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showOldPassword ? 'text' : 'password'"
            @click:append="showOldPassword = !showOldPassword"
          ></v-text-field>
          <v-text-field
            v-model="newpassword1"
            label="password attuale"
            :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword1 ? 'text' : 'password'"
            @click:append="showPassword1 = !showPassword1"
          ></v-text-field>
          <v-text-field
            v-model="newpassword2"
            label="password attuale"
            :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword2 ? 'text' : 'password'"
            @click:append="showPassword2 = !showPassword2"
          ></v-text-field>
          <p v-if="!passwordValid" class="red--text">
            {{ passwordMessage }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="changePassword()"
            :disabled="!passwordValid"
            >OK</v-btn
          >
          <v-btn color="primary" @click="changePasswordDialog = false"
            >Annulla</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row v-if="isAdmin">
      <v-col
        class="col-sm-12 align-items-left"
        align="left"
        variant="outline-primary"
        align-self="end"
      >
        <v-card>
          <v-container>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="2">
                <v-btn color="primary" @click="changePasswordDialog = true"
                  >Cambia password</v-btn
                >
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <v-row>
              <v-col cols="4" align="right">Username: </v-col>
              <v-col cols="8" class="value">{{ profileValues.username }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4" align="right">Nome: </v-col>
              <v-col cols="8" class="value">{{
                profileValues.firstname
              }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4" align="right">Cognome: </v-col>
              <v-col cols="8" class="value">{{ profileValues.lastname }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4" align="right"
                >Email
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" v-bind="attrs" v-on="on">
                      mdi-information
                    </v-icon>
                  </template>
                  <span
                    >Le email associate all'account saranno utilizzate per
                    finalit&agrave; di recupero credenziali</span
                  > </v-tooltip
                >:
              </v-col>
              <v-col cols="8" class="value">
                <v-row
                  v-for="(email, index) in profileValues.emails"
                  :key="index"
                  dense
                >
                  <v-col cols="6">
                    {{ email }}
                  </v-col>
                  <v-col>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          icon
                          @click="
                            emailToRemove = email;
                            removeEmailDialog = true;
                          "
                        >
                          <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Rimuovi</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          icon
                          @click="
                            form.email = '';
                            addEmailDialog = true;
                          "
                        >
                          <v-icon>mdi-plus-box</v-icon>
                        </v-btn>
                      </template>
                      <span>Aggiungi email</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" align="right">Gruppi: </v-col>
              <v-col class="value">
                <div
                  v-for="(group, index) in profileValues.groups"
                  v-bind:key="index"
                >
                  {{ group }}
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import Vuex from "vuex";
const jwt = require("jsonwebtoken");
const axios = require("axios");
const qs = require("querystring");

Vue.use(Vuex);

export default {
  name: "UserProfile",
  components: {},
  data() {
    return {
      payload: jwt.decode(localStorage.getItem("auth_token")),
      profileValues: {},
      removeEmailDialog: false,
      addEmailDialog: false,
      changePasswordDialog: false,
      showPassword1: false,
      showPassword2: false,
      showOldPassword: false,
      emailToRemove: "",
      emailToAdd: "",
      equalPasswords: true,
      form: {
        email: "",
      },
      oldpassword: "",
      newpassword1: "",
      newpassword2: "",
      emailErrorDismissSecs: 3,
      emailErrorDismissCountDown: 0,
      emailErrorMessage: "",
      passwordErrorDismissSecs: 3,
      passwordErrorDismissCountDown: 0,
      passwordErrorMessage: "",
      validEmail: false,
      alertVariant: "danger",
      passwordValid: true,
      passwordMessage: "",
      showSnackbar: false,
      timeout: -1,
      message: "",
      color: "error",
      email: "",
      emailValid: true,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.state.isAdmin;
    },
    isLoading() {
      return this.$store.state.isLoading;
    },
  },
  created() {
    var currentJWT = localStorage.getItem("auth_token");
    this.payload = jwt.decode(currentJWT);
    var component = this;
    component.$store.state.isLoading = true;
    var parameters = { username: this.payload.username };
    axios
      .post(
        process.env.VUE_APP_IPA_API + "/getuserinfo",
        qs.stringify(parameters),
        {
          withCredentials: true,
          headers: {
            authorization: "Bearer " + currentJWT,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then(function (response) {
        localStorage.setItem("auth_token", response.data.jwt);
        component.profileValues = response.data;
        component.$store.state.isLoading = false;
      })
      .catch(function (response) {
        component.qui = response.data;
        component.$store.state.isLoading = false;
      });
  },
  methods: {
    onSubmit() {
      // this.bar = 'submitted'
    },
    logout() {
      try {
        localStorage.clear();
        this.$router.push("/login");
      } catch (error) {
        console.log(error);
      }
    },
    emailErrorCountDownChanged(dismissCountDown) {
      this.emailErrorDismissCountDown = dismissCountDown;
    },
    passwordErrorCountDownChanged(dismissCountDown) {
      this.passwordErrorDismissCountDown = dismissCountDown;
    },
    emailErrorShowAlert() {
      this.emailErrorDismissCountDown = this.emailErrorDismissSecs;
    },
    async removeEmail() {
      var currentJWT = localStorage.getItem("auth_token");
      var payload = jwt.decode(currentJWT);
      var parameters = {
        username: payload.username,
        email: this.emailToRemove,
      };
      this.$store.state.isLoading = true;

      try {
        let response = await axios.post(
          process.env.VUE_APP_IPA_API + "/deluseremail",
          qs.stringify(parameters),
          {
            withCredentials: true,
            headers: {
              authorization: "Bearer " + currentJWT,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        // update local JWT
        localStorage.setItem("auth_token", response.data.jwt);
        if (response.data.httpStatus === 200) {
          this.profileValues.emails = response.data.email;
          this.timeout = 3000;
          this.message = response.data.longMessage;
          this.color = "success";
          this.showSnackbar = true;
          this.removeEmailDialog = false;
        } else {
          this.timeout = 4;
          this.message = response.data.longMessage;
          this.color = "error";
          this.showSnackbar = true;
          this.removeEmailDialog = false;
        }
        this.$store.state.isLoading = false;
      } catch (response) {
        this.timeout = 4000;
        this.message = response;
        this.color = "error";
        this.showSnackbar = true;

        this.$store.state.isLoading = false;
      }
    },
    async addEmail() {
      const currentJWT = localStorage.getItem("auth_token");
      let payload = jwt.decode(currentJWT);
      const parameters = { username: payload.username, email: this.email };
      this.$store.state.isLoading = true;
      this.addEmailDialog = false;

      try {
        let response = await axios.post(
          process.env.VUE_APP_IPA_API + "/adduseremail",
          qs.stringify(parameters),
          {
            withCredentials: true,
            headers: {
              authorization: "Bearer " + currentJWT,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        // update local JWT
        localStorage.setItem("auth_token", response.data.jwt);
        if (response.data.httpStatus === 200) {
          this.profileValues.emails = response.data.email;
          this.timeout = 3000;
          this.message = response.data.longMessage;
          this.color = "success";
          this.showSnackbar = true;
        } else {
          this.timeout = 4000;
          this.timeout = response.data.longMessage;
          this.color = "error";
          this.showSnackbar = true;
        }
        this.$store.state.isLoading = false;
      } catch (response) {
        this.timeout = 4000;
        this.message = response;
        this.color = "error";
        this.$store.state.isLoading = false;
        this.showSnackbar = true;
      }
    },
    checkPasswords() {
      if (
        !this.oldpassword ||
        this.oldpassword === "" ||
        !this.newpassword1 ||
        this.newpassword1 === "" ||
        !this.newpassword2 ||
        this.newpassword2 === ""
      ) {
        this.passwordMessage =
          "Le password (vecchia e nuove) sono obbligatorie";
        this.passwordValid = false;
        return;
      } else if (this.newpassword1 !== this.newpassword2) {
        this.passwordMessage = "Le password non coincidono";
        this.passwordValid = false;
        return;
      }
      this.passwordValid = true;
      this.passwordMessage = "";
    },
    async changePassword() {
      var component = this;
      // check if new passwords are equal
      if (!this.passwordValid) {
        return false;
      }
      this.$store.state.isLoading = true;
      var currentJWT = localStorage.getItem("auth_token");
      var payload = jwt.decode(currentJWT);
      var parameters = {
        username: payload.username,
        old_password: this.oldpassword,
        new_password: this.newpassword1,
      };
      try {
        let response = await axios.post(
          process.env.VUE_APP_IPA_API + "/changepassword",
          qs.stringify(parameters),
          {
            withCredentials: true,
            headers: {
              authorization: "Bearer " + currentJWT,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        // update local JWT
        localStorage.setItem("auth_token", response.data.jwt);
        if (response.data.httpStatus === 200) {
          this.timeout = 3000;
          this.message = response.data.longMessage;
          this.color = "success";
          this.showSnackbar = true;
          this.changePasswordDialog = false;
        } else {
          this.timeout = 3000;
          this.message = response.data.longMessage;
          this.color = "error";
          this.showSnackbar = true;
        }
        component.$store.state.isLoading = false;
      } catch (response) {
        this.timeout = 3000;
        this.message = response;
        this.color = "danger";
        this.$store.state.isLoading = false;
        this.showSnackbar = true;
      }
    },
    showRemoveEmail($event, modalId, email) {
      this.emailToRemove = email;
      this.$bvModal.show(modalId);
    },
    showAddEmail($event, modalId) {
      this.$bvModal.show(modalId);
    },
    showChangePassword($event, modalId) {
      this.$bvModal.show(modalId);
    },
  },
  watch: {
    oldpassword() {
      this.checkPasswords();
    },
    newpassword1() {
      this.checkPasswords();
    },
    newpassword2() {
      this.checkPasswords();
    },
    email() {
      const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      if (this.email.match(mailformat)) {
        this.emailValid = true;
      } else {
        this.emailValid = false;
      }
    },
  },
};
</script>
<style scoped>
.title {
  background-color: #003366;
  color: #ffffff;
  height: 100px;
}
.content {
  min-height: 500px;
}
pre {
  font-size: 80%;
}
.label {
  text-align: right;
}
.value {
  text-align: left;
  font-weight: bold;
}
.riga {
  margin: 6px;
}
.smallicon {
  width: 16px;
  float: right;
  cursor: pointer;
}
.smalliconinfo {
  width: 16px;
}
.smalliconplus {
  width: 16px;
  cursor: pointer;
}
</style>
