<template>
  <v-container class="header px-1 py-0">
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="10" class="my-0">
        <v-row class="title align-items-center py-0" align="center">
          <v-col cols="4" class="py-0">
            <v-img
              alt="D.I. logo"
              width="100%"
              src="../assets/unimi-DI-logo-neg.png"
            ></v-img>
          </v-col>
          <v-col cols="8" class="text-right" align-self="end">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-bind="attrs" v-on="on">
                  <v-icon dark>mdi-account-box</v-icon>
                  <v-icon dark>mdi-triangle-small-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item class="pointer">
                  <v-list-item-title>Profile</v-list-item-title>
                </v-list-item>
                <v-list-item class="pointer">
                  <v-list-item-title @click="logout">Logout</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
const jwt = require("jsonwebtoken");

export default {
  name: "TopHeader",
  components: {},
  data() {
    return {
      payload: jwt.decode(localStorage.getItem("auth_token")),
      tab3content: "",
    };
  },
  computed: {
    isAdmin() {
      return this.$store.state.isAdmin;
    },
    isLoading() {
      return this.$store.state.isLoading;
    },
  },
  methods: {
    logout() {
      try {
        localStorage.clear();
        this.$router.push("/login");
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.title {
  background-color: #003366;
  color: #ffffff;
  height: 100px;
}
.pointer {
  cursor: pointer;
}
.content {
  min-height: 500px;
}
pre {
  font-size: 80%;
}
</style>
