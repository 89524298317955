import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#003366',
                secondary: '#707173',
                success: '#009933',
                error: '#b71c1c',
                background: '#707173',
            },
            dark: {
                primary: '#003366',
                secondary: '#707173',
                success: '#009933',
                error: '#b71c1c',
                background: '#707173',
            },
        },
    },
    breakpoint: {
        mobileBreakpoint: 'sm' // This is equivalent to a value of 960
    },
});
